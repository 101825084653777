<template>
  <div class="header">
    <!-- 左边图片 -->
    <div class="page-logo" v-if="isDashboard">
      <router-link to="/index">
        <img src="../../assets/imgs/logo.png" alt="logo" class="logo-default" />
      </router-link>
    </div>
    <div class="page-logo" v-else>
      <router-link to="/dashboard">
        <img src="../../assets/imgs/logo.png" alt="logo" class="logo-default" />
      </router-link>
    </div>
    <!-- 右边菜单按钮 -->
    <div class="header_content">
      <div class="header_right">
        <span @click="changeIsActive(0)" :class="{ active: current == 0 }">
          <i class="iconfont">&#xe608;</i>
          数据看板
        </span>
        <!-- 项目管理 -->

        <span @click="goProject" :class="{ active: current == 1 }">
          <i class="iconfont">&#xe609;</i>
          项目管理
        </span>

        <!-- 策略任务 -->
        <span @click="changeIsActive(5)" :class="{ active: current == 6 }">
          <i class="iconfont">&#61685;</i>
          策略任务
        </span>
        <!-- <el-dropdown :class="{'active':current==5}">
            <span class="el-dropdown-link">
              <span :style="{color: current != 1 ? 'isColor' : ''}" @click="goProject"><i class="iconfont">&#xe609</i>策略任务</span>
              <i :style="{color: current != 1 ? 'isColor' : ''}" class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <span @click="goProject">
                <el-dropdown-item>我的项目</el-dropdown-item>
              </span>
              <span @click="goSchedule">
                <el-dropdown-item>我的策略</el-dropdown-item>
              </span>
              <span @click="goTask">
                <el-dropdown-item>我的任务</el-dropdown-item>
              </span>
            </el-dropdown-menu>
          </el-dropdown> -->

        <span @click="changeIsActive(3)" :class="{ active: current == 3 }">
          <i class="iconfont icon-yduibianxie"></i>
          爬虫中心
        </span>

        <span @click="changeIsActive(4)" :class="{ active: current == 4 }">
          <i class="el-icon-s-tools"></i>
          配置管理
        </span>
        <span @click="goHelpCenter()">
          <i class="iconfont icon-bangzhuzhongxin"></i>
          帮助中心
        </span>
        <span @click="goPluginDownload()" :class="{ active: current == 7 }">
          <i class="iconfont icon-st-block"></i>
          插件下载
        </span>

        <el-dropdown :class="{ active: current == 5 }">
          <span class="el-dropdown-link">
            <i class="iconfont icon-user"></i>
            <span class="pl0">{{ userInfo.name }}</span>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <span @click="goUserCenter">
                <el-dropdown-item>个人中心</el-dropdown-item>
              </span>
              <span @click="updatePassword">
                <el-dropdown-item>个人权益</el-dropdown-item>
              </span>
              <span @click="exit()">
                <el-dropdown-item>退出</el-dropdown-item>
              </span>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <!-- 嵌套子网站 -->
    <!-- <div v-show="false">
      <iframe
        ref="zhihuifeng"
        src="https://www.zhihuifeng.com/free/index.html"
      ></iframe>
      <iframe
        ref="campbell"
        src="https://www.campbellchina.com/free/index.html"
      ></iframe>
      <iframe ref="sw" src="https://www.smartsowo.com/free/sw.html"></iframe>
    </div> -->
  </div>
</template>
<script>
import { goLogin } from "@/utils/login";

// import CryptoJS from "crypto-js";
export default {
  data() {
    return {
      isDashboard: false, // 点击头部是否跳转到首页
      current: 0, // 当前页面
      userInfo: "", // 用户信息
      isLogin: false, // 判断用户是否登录
      isColor: "#000",
    };
  },

  mounted() {
    var url = window.location.href;
    // 点击logo跳转
    if (url.indexOf("dashboard") != -1) {
      this.isDashboard = true;
    } else {
      this.isDashboard = false;
    }

    // 点击按头部切换界面
    if (url.indexOf("dashboard") != -1) {
      this.isDashboard = true;
      this.current = 0;
    } else if (url.indexOf("/setting") != -1) {
      this.current = 4;
    } else if (url.indexOf("/project") != -1) {
      this.current = 1;
    } else if (url.indexOf("/task-schedule") != -1) {
      this.current = 6;
    } else if (url.indexOf("/spider") != -1) {
      this.current = 3;
    } else if (url.indexOf("/user") != -1) {
      this.current = 5;
    } else if (url.indexOf("/plugin") != -1) {
      this.current = 7;
    }
    //判断用户是否登录
    this.checkUserIfLogin();
  },
  methods: {
    /*判断用户是否登录*/
    checkUserIfLogin() {
      if (this.$cookies.isKey("tokenWeb")) {
        let userInfo = this.$cookies.get("userInfo");
        if (userInfo && !userInfo.ifLoginSpider) {
          this.httpPost("/user/v1/getUserPermission", null).then((res) => {
            if (res && res.code === 0) {
              userInfo.spiderPermission = res.data;
              userInfo.ifLoginSpider = true;
              this.$cookies.set("userInfo", JSON.stringify(userInfo));
            }
          });
        }
        this.userInfo = userInfo;
      } else {
        this.$message({
          message: "登录信息已失效,请重新登录",
          type: "warning",
        });
        this.$cookies.remove("tokenWeb", "/", "ringdata.com");
        this.$cookies.remove("userInfo", "/", "ringdata.com");
        this.$cookies.remove("tokenWebRefresh", "/", "ringdata.com");
        this.$cookies.remove("tokenWeb");
        this.$cookies.remove("userInfo");
        this.$cookies.remove("tokenWebRefresh");
        // this.$router.push({
        //   path: "/login",
        // });
        goLogin();
      }
    },

    /*点击头部按钮的事件*/
    changeIsActive(flag) {
      if (flag == 0) {
        this.$router.push({
          path: "/dashboard",
        });
      } else if (flag == 1) {
        this.$router.push({
          path: "/project",
        });
      } else if (flag == 2) {
        this.$router.push({
          path: "/task",
        });
      } else if (flag == 3) {
        this.$router.push({
          path: "/spider",
        });
      } else if (flag == 4) {
        this.$router.push({
          path: "/setting",
        });
      } else if (flag == 5) {
        this.$router.push({ path: "/task-schedule" });
      } else if (flag == 6) {
        this.goApplication();
      }
    },

    /* 跳转到我的项目 */
    goProject() {
      this.$router.push({ path: "/project" });
    },

    /* 跳转到我的任务 */
    goTask() {
      this.$router.push({ path: "/task" });
    },

    /* 跳转到我的策略 */
    goSchedule() {
      this.$router.push({ path: "/schedule" });
    },

    /*跳转云质析*/
    goApplication() {},

    /*个人账户中心*/
    goUserCenter() {
      // this.$router.push({
      //   path: "/user/center"
      // });
      window.open("https://i.ringdata.com/index");
    },

    /*修改密码*/
    updatePassword: function () {
      // this.$router.push({
      //   path: '/user/modify/pass',
      // });
      this.$router.push("/user/equity");
    },

    /*授权云质析 */
    goAuthorization() {
      this.$router.push({
        path: "/user/authorization",
      });
    },

    goHelpCenter() {
      window.open("https://help.ringdata.com/project/caiji");
    },
    goPluginDownload() {
      this.$router.push({
        path: "/plugin/download",
      });
    },
    /*退出账户*/
    exit: function () {
      this.httpPost("/centerAuth/v1/exit").then((res) => {
        if (res && res.code === 0) {
          this.$message({
            message: "退出成功",
            type: "success",
          });
          this.$cookies.remove("tokenWeb", "/", "ringdata.com");
          this.$cookies.remove("userInfo", "/", "ringdata.com");
          this.$cookies.remove("tokenWeb");
          this.$cookies.remove("userInfo");
          this.loginOut();
          setTimeout(() => {
            // this.$router.push("/login");
            goLogin();
          }, 1.5 * 1000);
        } else if (res) {
          this.$message.error(res.message);
        }
      });
    },

    /*免登录清除Cookie*/
    loginOut() {
      let param = {
        type: "rm",
        params: {},
      };
      this.$refs.zhihuifeng.contentWindow.postMessage(
        param,
        "https://www.zhihuifeng.com"
      );
      this.$refs.campbell.contentWindow.postMessage(
        param,
        "https://www.campbellchina.com"
      );
      this.$refs.sw.contentWindow.postMessage(
        param,
        "https://www.smartsowo.com"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
// 头部 个人账户 下拉框-样式
.el-dropdown-menu {
  span {
    .el-dropdown-menu__item {
      &:hover {
        background-color: #e2cae2;
        color: #760478;
      }
    }
  }
}
// 头部样式
.header {
  height: 60px;
  line-height: 60px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ddd;
  padding: 0;
  // 左边
  .page-logo {
    height: 60px;
    position: absolute;
    top: 0;
    left: 0;
    line-height: 60px;
    padding: 0px 10px 0px;
    img {
      vertical-align: middle;
      width: 220px;
      height: 55px;
    }
  }
  // 右边
  .header_content {
    height: 60px;
    display: block;
    overflow: hidden;
    width: 100%;
    .header_right {
      float: right;
      cursor: pointer;
      > span,
      .el-dropdown {
        line-height: 60px;
        display: inline-block;
        padding: 0px 10px;
        margin: 0 auto;
        &.active {
          color: #fff;
          background-color: #760478;
        }
      }
    }
  }
}
</style>
